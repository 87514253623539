import gql from 'graphql-tag';

export default gql`
query getAllFoldersAndProjectsByFolderId($folderId: Int!) {
  folders(where: {parent_folder_id: {_eq: $folderId}}) {
    name
    folder_id
    slug
    lookup_entries { slug_path }
    order_index
    item_path
    plugins {
      handle
      enabled
    }
    group {
      name
      slug
      domain
    }
  }
  projects(where: {parent_folder_id: {_eq: $folderId}}) {
    name
    project_id
    slug
    thumbnails
    order_index
    item_path
    lookup_entries { slug_path }
    plugins {
      handle
      enabled
    }
    group {
      name
      slug
      domain
    }
  }
}
`;
