import gql from 'graphql-tag';

export default gql`
query getAllChildPageIdsOfProject($projectId: Int!) {
  projects(where: {project_id: {_eq: $projectId}}) {
    child_pages(order_by: {order_index: asc}) {
      page_id
      published
      slug
      name
      thumbnails
      item_path
    }
  }
}
`;
