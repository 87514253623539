<script>
import { Line } from 'vue-chartjs';

export default {
  extends: Line,
  props: {
    readsPerDayObject: {
      type: Array,
      default: null,
    },
    dateRange: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      gradient: null,
    };
  },
  mounted() {
    const allDaysInRange = this.getDates(this.dateRange.startDate, this.dateRange.endDate);
    const labels = [];
    const values = [];
    allDaysInRange.forEach((day) => {
      labels.push(day);
      const elementOfTheDay = this.readsPerDayObject.find((element) => element.label === day);
      if (elementOfTheDay) {
        values.push(elementOfTheDay.value);
      } else {
        values.push(0);
      }
    });
    this.gradient = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, 'rgba(81, 209, 130, 0.5)');
    this.gradient.addColorStop(0.5, 'rgba(81, 209, 130, 0.25)');
    this.gradient.addColorStop(1, 'rgba(81, 209, 130, 0)');

    this.renderChart(
      {
        labels,
        datasets: [
          {
            borderColor: '#51d182',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: 'white',
            backgroundColor: this.gradient,
            data: values,
            lineTension: 0,
          },
        ],
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            ticks: {
              autoSkip: true,
              maxTicksLimit: 5,
              backdropColor: 'white',
            },
          }],
        },
      },
    );
  },
  methods: {
    getDates(startDate, endIsoString) {
      const date = new Date(startDate);
      const endDate = new Date(endIsoString);
      const dates = [];

      while (date <= endDate) {
        dates.push(this.formatDate(new Date(date)));
        date.setDate(date.getDate() + 1);
      }
      return dates;
    },
    formatDate(date) {
      const d = new Date(date);
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      const year = d.getFullYear();
      if (month.length < 2) {
        month = `0${month}`;
      }
      if (day.length < 2) {
        day = `0${day}`;
      }
      return [year, month, day].join('-');
    },
  },
};
</script>
