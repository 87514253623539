import gql from 'graphql-tag';

export default gql`
mutation(
  $isIndicatorLoading: Boolean!,
) {
  setLoadingIndicator(
    isIndicatorLoading: $isIndicatorLoading,
  ) @client
}
`;
